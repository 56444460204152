<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-row>
                <v-breadcrumbs :items="itemsLinks">
                    <template v-slot:divider>
                        <v-icon>chevron_right</v-icon>
                    </template>
                </v-breadcrumbs>
                <transition 
                    name="fade"
                >
                    <v-col v-if="switchPeriodos" cols="12" xs="12" sm="12" md="12" class="pa-0 mb-10">
                        <ValidationObserver ref="formTabla" v-slot="{ invalid }">
                            <v-row class="pa-0  mt-0 mb-5">
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                    <div style="display: flex; width:100%; height:100%; justify-content: flex-start;">
                                        <hr class="vr"/>
                                        <p class="tblName pb-2">Periodos de pago</p>
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0 d-flex justify-end">
                                    <span class="spanSwitch d-flex align-center justify-center text-center mr-4">
                                        <v-switch
                                            v-model="switchPeriodos"
                                            :label="switchPeriodos?'Ocultar lista de periodos':'Ver lista de periodos'"
                                            hide-details
                                            class="mb-3"
                                        ></v-switch>
                                    </span>
                                    <v-btn
                                        rounded
                                        color="#004BAF"
                                        class="btnAdd"
                                        right
                                        @click="guardarPeriodos()"
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <perfect-scrollbar class="div-tabla-periodos firstth lastth">
                                <table class="tbl-incidencias tabla-periodos">
                                    <thead class="tbl-header tabla-thead-periodos">
                                        <tr>
                                            <th rowspan=2 class="pl-4 firstth"><div>Periodo</div> </th> <!--  -->
                                            <th rowspan=2><span>Fecha inicio</span> </th>
                                            <th rowspan=2><span>Fecha fin</span></th>
                                            <th rowspan=2><span>Fecha pago</span></th>
                                            <th rowspan=2><span>Mes de acumulación</span></th>
                                            <th colspan="2" class="thTitulos px-8 text-center"><span>Mes</span></th>
                                            <th colspan="2" class="thTitulos px-8 text-center"><span>Bimestre</span></th>
                                            <th colspan="2" class=" thTitulos px-8 text-center lastth"><span>Año</span></th> <!--  -->
                                        </tr>
                                        <tr>
                                            <th class="thCheck pl-8"    style="top: 31px;"><span>Inicio</span></th>
                                            <th class="thCheck"         style="top: 31px;"><span>Fin</span></th>
                                            <th class="thCheck pl-8"    style="top: 31px;"><span>Inicio</span></th>
                                            <th class="thCheck"         style="top: 31px;"><span>Fin</span></th>
                                            <th class="thCheck pl-8"    style="top: 31px;"><span>Inicio</span></th>
                                            <th class="thCheck"         style="top: 31px;"><span>Fin</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="tabla-tbody-periodos">
                                        <tr v-if="isLoadingTablaPeriodos">
                                            <td colspan="11">
                                                <v-progress-linear
                                                    :active="true"
                                                    class="loading"
                                                    color="blue"
                                                    indeterminate
                                                ></v-progress-linear>
                                            </td>
                                        </tr>
                                        <tr v-if="datosTabla.length == 0" >
                                            <td class="emptyTableComplementos" colspan="11">
                                                No hay elementos para mostrar
                                            </td>
                                        </tr>
                                        <template v-else v-for="(periodo, index) in datosTabla">
                                            <tr :key="index" class="trBody">
                                                <td>
                                                    <span class="pl-4">
                                                        {{periodo.numero}}
                                                    </span>
                                                </td>

                                                <template v-if="tipoNomina.aplicar_fechas_corte == false">
                                                    <td class="py-2">
                                                        <VuetifyDatePicker
                                                            outlined
                                                            rules="required"
                                                            :valorDefecto="periodo.fecha_inicial"
                                                            v-model="periodo.fecha_inicial"
                                                            label=""
                                                            placeholder="dd/mm/aaaa"
                                                            dense
                                                            hideDetails
                                                            :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                        />
                                                    </td>
                                                    <td>
                                                        <VuetifyDatePicker
                                                            outlined
                                                            rules="required"
                                                            :valorDefecto="periodo.fecha_final"
                                                            v-model="periodo.fecha_final"
                                                            label=""
                                                            placeholder="dd/mm/aaaa"
                                                            dense
                                                            hideDetails
                                                            :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                        />
                                                    </td>
                                                </template>
                                                <template v-else>
                                                    <td class="py-2">
                                                        <VuetifyDatePicker
                                                            outlined
                                                            rules="required"
                                                            :valorDefecto="periodo.fecha_inicial_corte"
                                                            v-model="periodo.fecha_inicial_corte"
                                                            label=""
                                                            placeholder="dd/mm/aaaa"
                                                            dense
                                                            hideDetails
                                                            :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                        />
                                                    </td>
                                                    <td>
                                                        <VuetifyDatePicker
                                                            outlined
                                                            rules="required"
                                                            :valorDefecto="periodo.fecha_final_corte"
                                                            v-model="periodo.fecha_final_corte"
                                                            label=""
                                                            placeholder="dd/mm/aaaa"
                                                            dense
                                                            hideDetails
                                                            :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                        />
                                                    </td>
                                                </template>

                                                
                                                <td>
                                                    <VuetifyDatePicker
                                                        outlined
                                                        rules="required"
                                                        :valorDefecto="periodo.fecha_de_pago"
                                                        v-model="periodo.fecha_de_pago"
                                                        label=""
                                                        placeholder="dd/mm/aaaa"
                                                        dense
                                                        hideDetails
                                                        :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                    />
                                                </td>
                                                <td>
                                                    <v-autocomplete
                                                        outlined
                                                        dense
                                                        :items="meses"
                                                        item-text="nombre"
                                                        item-value="nombre"
                                                        persistent-hint
                                                        v-model="periodo.mes_de_acomulacion" 
                                                        hide-details
                                                        :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                    >
                                                    </v-autocomplete>
                                                </td>
                                                <td>
                                                    <v-checkbox
                                                        v-model="periodo.inicio_de_mes"
                                                        hide-details
                                                        :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                        class="mt-0 pt-0 pl-8"
                                                    ></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox
                                                        v-model="periodo.fin_de_mes"
                                                        hide-details
                                                        :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                        class="mt-0 pt-0"
                                                    ></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox
                                                        v-model="periodo.inicio_de_bimestre"
                                                        hide-details
                                                        :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                        class="mt-0 pt-0 pl-8"
                                                    ></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox
                                                        v-model="periodo.fin_de_bimestre"
                                                        hide-details
                                                        :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                        class="mt-0 pt-0"
                                                    ></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox
                                                        v-model="periodo.inicio_de_anio"
                                                        hide-details
                                                        :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                        class="mt-0 pt-0 pl-8"
                                                    ></v-checkbox>
                                                </td>
                                                <td>
                                                    <v-checkbox
                                                        v-model="periodo.fin_de_anio"
                                                        hide-details
                                                        :disabled="(periodo.cerrado && periodo.estatus == 'Acumulado')"
                                                        class="mt-0 pt-0"
                                                    ></v-checkbox>
                                                </td>
                                            </tr>
                                        </template>
                                        <tr v-if="datosTabla.length != 0" >
                                            <td class="tdBlanco" colspan="11">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </perfect-scrollbar>
                            
                        </ValidationObserver>
                    </v-col>
                </transition>
                <transition 
                    name="fade"
                >
                    <v-col v-if="!switchPeriodos" cols="12" xs="12" sm="12" md="12">
                        <data-table
                            ref="tabla"
                            :tableName="'Periodos de pago'"
                            :columns="columns"
                            :items="tableData.data"
                            :paginationInfo="tableData.paginatorInfo"
                            @pagination="getPaginationInfo"
                            :showAdvancedFilters="true"
                            :filters="filters"
                            @loadModalData="loadModalData"
                            @setFilters="setFilters"
                            :perPage="[10,25,50,100]"
                            :loading="isLoading"
                        >
                            <template slot="actionButtonsLeft">
                                <span class="spanSwitch d-flex align-center justify-center text-center mr-4">
                                    <v-switch
                                        v-model="switchPeriodos"
                                        :label="switchPeriodos?'Ocultar lista de periodos':'Ver lista de periodos'"
                                        hide-details
                                        class="mb-3"
                                    ></v-switch>
                                </span>

                                <div class="botonAgregar">
                                    <v-btn 
                                        v-if="showGenerar"
                                        color="#004BAF" 
                                        class="btnGenerarPeriodo" 
                                        right
                                        rounded 
                                        @click="generarPeriodos()"
                                        :disabled="deshabilitarGenerar"
                                    >
                                        <v-icon dark class="ml-n1 mr-2">autorenew</v-icon>Generar periodos
                                    </v-btn>
                                </div>
                                
                                <div class="botonAgregar">
                                    <v-btn
                                        rounded
                                        color="#004BAF"
                                        class="btnAdd"
                                        right
                                        @click="abrirModal('add')"
                                    >
                                        <v-icon dark class="mr-2">add</v-icon>Agregar
                                        <div class="mr-4"></div>
                                    </v-btn>
                                </div>
                            </template>

                            <template slot="filters">
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-text-field 
                                        outlined 
                                        label="Código" 
                                        v-model="numero" 
                                        v-mask="'N########'"
                                    >    
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        label="Mes de acumulación"
                                        :items="meses"
                                        item-text="nombre"
                                        item-value="nombre"
                                        persistent-hint
                                        v-model="mes_de_acomulacion" >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        label="Año fiscal"
                                        :items="aniosFiltro"
                                        item-text="anio.nombre"
                                        item-value="id"
                                        persistent-hint
                                        v-model="anioFiltro" >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <VuetifyDatePicker
                                        outlined
                                        v-model="fecha_de_pago"
                                        label="Fecha de pago"
                                        placeholder="dd/mm/aaaa"
                                        ref="FechaPagoFilter"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <VuetifyDatePicker
                                        outlined
                                        v-model="fecha_inicial"
                                        label="Fecha inicial"
                                        placeholder="dd/mm/aaaa"
                                        ref="FechaInicialFilter"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <VuetifyDatePicker
                                        outlined
                                        v-model="fecha_final"
                                        label="Fecha final"
                                        placeholder="dd/mm/aaaa"
                                        ref="FechaFinalFilter"
                                    />
                                </v-col>
                            </template>
                            
                            <tbody slot="body" slot-scope="{ data }">
                                <tr :key="item.id" v-for="item in data">
                                    <td><div>{{item.numero}}</div></td>
                                    <td><div>{{item.mes_de_acomulacion.substring(0,3)}}</div></td>

                                    <template v-if="item.tipoNomina.aplicar_fechas_corte == false">
                                        <td><div>{{dateFormat(item.fecha_inicial, 'tabla')}}</div></td>
                                        <td><div>{{dateFormat(item.fecha_final, 'tabla')}}</div></td>
                                    </template>
                                    <template v-else>
                                        <td><div>{{dateFormat(item.fecha_inicial_corte, 'tabla')}}</div></td>
                                        <td><div>{{dateFormat(item.fecha_final_corte, 'tabla')}}</div></td>
                                    </template>

                                    
                                    <td><div>{{dateFormat(item.fecha_de_pago, 'tabla')}}</div></td>
                                    <td
                                        style="
                                            min-width: 250px !important;
                                            max-width: 301px !important;
                                            padding-left: 8px;
                                            padding-right: 8px;
                                            text-align: center;
                                        "
                                    >
                                        <div>
                                            <v-chip
                                                class="vChip"
                                                :color="getColor(item.getEstatus)"
                                                :text-color="getColorTexto(item.getEstatus)"
                                            >
                                                {{item.getEstatus}}
                                            </v-chip>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="tblOpciones">
                                            <v-menu  v-if="item.cerrado === false || (item.cerrado === true && item.getEstatus == 'Desacumulado')" buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon 
                                                        fab
                                                        dark
                                                        small
                                                        class="elevation-0"
                                                    >
                                                        <template  v-for="(item,valor) in attrs" >
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                        </template>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item
                                                        v-if="item.cerrado === false || (item.cerrado === true && item.getEstatus == 'Desacumulado')"
                                                        @click="abrirModal('update', item)"
                                                    >
                                                        <v-list-item-title>Editar</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item
                                                        v-if="item.actual === true && item.cerrado === false"
                                                        @click="abrirModalPrecalculo(item)"
                                                    >
                                                        <v-list-item-title>Precalcular nómina</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item
                                                        v-if="item.cerrado === false"
                                                        @click="eliminar(item)"
                                                    >
                                                        <v-list-item-title>Eliminar</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>

                                            
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" v-show="item.cerrado === true && item.getEstatus != 'Desacumulado'" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                        <v-icon class="tamIconoBoton iconoDelgadoBoton" v-text="'$remove_red_eye_outline'"></v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Consultar</span>
                                            </v-tooltip>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </data-table>
                    </v-col>
                </transition>
                
            </v-row>
            <template>
                <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <template v-if="accion == 'add'">
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Código" rules="required">
                                                        <v-text-field 
                                                            outlined 
                                                            label="Código" 
                                                            v-model="periodo.numero" 
                                                            :error-messages="errors" 
                                                            required
                                                            v-mask="'#########'"
                                                            :disabled="visualizarPeriodo"
                                                        >    
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Dias de pago" rules="required|numeros_positivos">
                                                        <v-text-field 
                                                            outlined 
                                                            label="Dias de pago" 
                                                            class="pa-0 ma-0" 
                                                            disabled
                                                            v-model="periodo.dias_de_pago"
                                                            :error-messages="errors" 
                                                            required
                                                            v-on:keypress="presicionNumero($event,fields.values[index])"
                                                            oninput="if(this.value > 999 || this.value<0) this.value = 999; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                        >                                                    
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template v-if="accion == 'update'">
                                                <v-col cols="12" xs="12" sm="4" md="4" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Código" rules="required">
                                                        <v-text-field 
                                                            outlined 
                                                            label="Código" 
                                                            v-model="periodo.numero" 
                                                            :error-messages="errors" 
                                                            required
                                                            v-mask="'#########'"
                                                            :disabled="visualizarPeriodo"
                                                        >    
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="4" md="4" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Dias de pago" rules="required|numeros_positivos">
                                                        <v-text-field 
                                                            outlined 
                                                            label="Dias de pago" 
                                                            class="pa-0 ma-0" 
                                                            disabled
                                                            v-model="periodo.dias_de_pago" 
                                                            :error-messages="errors" 
                                                            required
                                                            v-on:keypress="presicionNumero($event,fields.values[index])"
                                                            oninput="if(this.value > 999 || this.value<0) this.value = 999; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                        >                                                    
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="4" md="4" class="py-0">
                                                    <v-container fluid>
                                                        <v-checkbox class="pa-0 ma-0" v-model="periodo.actual" label="Actual" :disabled="visualizarPeriodo"></v-checkbox>
                                                    </v-container>
                                                </v-col>
                                            </template>
                                            
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="periodo.fecha_inicial"
                                                    :valorDefecto="periodo.fecha_inicial"
                                                    label="Fecha inicial"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaInicial"
                                                    :disabled="visualizarPeriodo"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="periodo.fecha_final"
                                                    :valorDefecto="periodo.fecha_final"
                                                    label="Fecha final"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaFinal"
                                                    :disabled="visualizarPeriodo"
                                                />
                                            </v-col>
                                            <template v-if="tipoNomina.aplicar_fechas_corte">
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <VuetifyDatePicker
                                                        outlined
                                                        rules="required"
                                                        v-model="periodo.fecha_inicial_corte"
                                                        :valorDefecto="periodo.fecha_inicial_corte"
                                                        label="Fecha inicial corte"
                                                        placeholder="dd/mm/aaaa"
                                                        ref="FechaInicial"
                                                        :disabled="visualizarPeriodo"
                                                    />
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <VuetifyDatePicker
                                                        outlined
                                                        rules="required"
                                                        v-model="periodo.fecha_final_corte"
                                                        :valorDefecto="periodo.fecha_final_corte"
                                                        label="Fecha final corte"
                                                        placeholder="dd/mm/aaaa"
                                                        ref="FechaFinal"
                                                        :disabled="visualizarPeriodo"
                                                    />
                                                </v-col>
                                            </template>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-container fluid>
                                                    <v-switch class="pa-0 ma-0" :disabled="disabledSwitch" v-model="periodo.inicio_de_anio" label="Inicio de año"></v-switch>
                                                    <v-switch class="pa-0 ma-0" :disabled="disabledSwitch" v-model="periodo.fin_de_anio" label="Fin de año"></v-switch>
                                                    <v-switch class="pa-0 ma-0" :disabled="disabledSwitch" v-model="periodo.inicio_de_bimestre" label="Inicio de bimestre"></v-switch>
                                                    <v-switch class="pa-0 ma-0" :disabled="disabledSwitch" v-model="periodo.fin_de_bimestre" label="Fin de bimestre"></v-switch>
                                                </v-container>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-container fluid>
                                                    <v-switch class="pa-0 ma-0" :disabled="disabledSwitch" v-model="periodo.inicio_de_mes" label="Inicio de mes"></v-switch>
                                                    <v-switch class="pa-0 ma-0" :disabled="disabledSwitch" v-model="periodo.fin_de_mes" label="Fin de mes"></v-switch>
                                                    <v-switch class="pa-0 ma-0" v-model="periodo.especial" label="Especial" :disabled="visualizarPeriodo"></v-switch>
                                                </v-container>
                                            </v-col> 
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="periodo.fecha_de_pago"
                                                    :valorDefecto="periodo.fecha_de_pago"
                                                    label="Fecha de pago"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaPago"
                                                    :disabled="visualizarPeriodo"
                                                />
                                            </v-col>                                       
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Mes de acumulación" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Mes de acumulación"
                                                        :items="meses"
                                                        item-text="nombre"
                                                        item-value="nombre"
                                                        persistent-hint
                                                        v-model="periodo.mes_de_acomulacion"
                                                        required
                                                        :error-messages="errors" 
                                                        :disabled="visualizarPeriodo"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-text-field 
                                                    outlined 
                                                    label="Descripción" 
                                                    v-model="periodo.descripcion" 
                                                    :disabled="visualizarPeriodo"
                                                >                                                    
                                                </v-text-field>
                                            </v-col>
                                            <!--<v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-text-field 
                                                    outlined 
                                                    label="Número de factura" 
                                                    v-model="periodo.numero_de_factura"
                                                >                                                    
                                                </v-text-field>
                                            </v-col>-->
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Periodicidad" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Periodicidad"
                                                        :items="periodicidades"
                                                        item-text="descripcion"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="periodo.periodicidad_id"
                                                        required 
                                                        :readonly="!disabledSwitch"
                                                        :error-messages="errors" 
                                                        :disabled="visualizarPeriodo"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Tipo nómina" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Tipo nómina"
                                                        :items="tipoNominaSAT"
                                                        item-text="descripcion"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="periodo.tipo_nomina_SAT_id"
                                                        required 
                                                        :error-messages="errors" 
                                                        :disabled="visualizarPeriodo"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0 d-flex">
                                                <v-checkbox class="pa-0 ma-0" v-model="periodo.aplicar_tabla_diaria" label="Calcular ISR y subsidio de manera diaria" :disabled="visualizarPeriodo"></v-checkbox>
                                                <v-tooltip top class="pb-7">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                    </template>
                                                    <span class="pb-7 textTooltip">
                                                        En caso de no elegir esta opción, el cálculo se realizará <br>
                                                        de manera periódica.
                                                    </span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col v-if="periodo.especial" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <v-checkbox class="pa-0 ma-0" label="Incluir sueldo" v-model="periodo.agregar_sueldos" :disabled="visualizarPeriodo"></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                    <v-btn v-if="showGuardar" class="btnGuardar" @click="guardar()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogPrecalculo" persistent max-width="600px" class="borde-card" scrollable id='volver-arriba'>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalPrecalculo"></v-btn>
                    </template>
                    <perfect-scrollbar style="background:#FFF">
                        <v-card class="borde-card">
                            <ValidationObserver ref="formPrecalculo" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>
                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Periodo" rules="required">
                                                    <v-text-field 
                                                        outlined 
                                                        label="Código" 
                                                        v-model="periodo.numero" 
                                                        :error-messages="errors" 
                                                        required
                                                        disabled
                                                        v-mask="'N########'"
                                                    >
                                                    </v-text-field>    
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="periodo.fecha_de_pago"
                                                    label="Fecha de pago"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaPago"
                                                />
                                            </v-col>   
                                            <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                <v-radio-group v-model="incidencias_reloj_checador" row>
                                                    <template v-slot:label >
                                                        <div class="radio_group_01" style="width:220px !important;">Faltas de reloj checado y vacaciones de GDTH</div>
                                                    </template>
                                                    <v-radio label="SI" :value="true"></v-radio>
                                                    <v-radio label="NO" :value="false"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                <v-radio-group v-model="incidencias_capturadas" row>
                                                    <template v-slot:label>
                                                        <div class="radio_group_02" style="width:220px !important;">Incluir complementos</div>
                                                    </template>
                                                    <v-radio label="SI" :value="true"></v-radio>
                                                    <v-radio label="NO" :value="false"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <template>
                                                <v-col xs="12" sm="12" md="12" class="py-0 pt-4">
                                                    <v-autocomplete
                                                        v-model="modelo_empleados"
                                                        :items="items_empleados"
                                                        outlined
                                                        item-text="nombre_completo"
                                                        item-value="id"
                                                        label="Todos los empleados"
                                                        multiple
                                                        @change="cambio_emp()"
                                                    >
                                                        <template v-slot:selection="{ item, index }">
                                                            <v-chip v-if="index === 0">
                                                                <span>
                                                                    {{
                                                                        item.nombre_completo < 9
                                                                        ? item.nombre_completo
                                                                        : item.nombre_completo.substr(0, 8) + "..."
                                                                    }}
                                                                </span>
                                                            </v-chip>
                                                            <span
                                                                v-if="index === 1"
                                                                class="grey--text caption"
                                                            >(+{{ modelo_empleados.length - 1 }} otros)
                                                            </span>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                            </template>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion pt-0">
                                    <button class="btnCerrar modal-pull-right" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                    <v-btn class="btnGuardar modal-pull-right" @click="descargarReporte(null,'calcular')" :disabled="invalidBtnGuardar" :loading="btnGuardar">Guardar</v-btn>
                                    <v-btn class="btnGuardar modal-pull-right" @click="consultar()" :disabled="invalidBtnConsultar" :loading="btnConsultar">Consultar</v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

            <!----------modal calcular --------->
            <template class="borde-card">
                <v-dialog v-model="dialogCalculoTabla" persistent max-width="700px" scrollable class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalCalculoTabla"></v-btn>
                    </template>
                    
                        <v-card class="borde-card card_titulo">
                            <perfect-scrollbar>
                            <v-card-title class="justify-center">
                                <div class="headerModal">
                                    <img :src="imagenCalculoTabla" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModalCalculoTabla }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="pt-9">
                                <div id="padre" v-show="isLoadingCalculoTabla">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingCalculoTabla" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row class="pa-0 ma-0 informacionGeneral">
                                        <v-row class="px-9 pt-12 pb-7">
                                            <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                <p class="informacionGeneralTitulo">Información global</p>
                                            </v-col> 
                                            <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                <p class="informacionGeneralText">{{nombreEmpresa}}</p>
                                            </v-col>
                                            <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                <p class="mb-0 informacionGeneralSubtitle">Tipo de nómina</p>
                                                <p class="informacionGeneralText">{{periodicidad}}</p>
                                            </v-col>
                                            <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                <p class="mb-0 informacionGeneralSubtitle">Total de empleados:</p>
                                                <p class="informacionGeneralText">{{totalEmpleados}}</p>
                                            </v-col>

                                            <template v-if="periodo.tipo_nomina.aplicar_fechas_corte == false">
                                                <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Fecha inicial del periodo:</p>
                                                    <p class="informacionGeneralText">{{dateFormat(periodo.fecha_inicial, 'modal')}}</p>
                                                </v-col>
                                                <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Fecha final del periodo</p>
                                                    <p class="informacionGeneralText">{{dateFormat(periodo.fecha_final, 'modal')}}</p>
                                                </v-col> 
                                            </template>
                                            
                                            <!-- Se invierten las fechas -->
                                            <template v-else> 
                                                <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Fecha inicial del periodo:</p>
                                                    <p class="informacionGeneralText">{{dateFormat(periodo.fecha_inicial_corte, 'modal')}}</p>
                                                </v-col>
                                                <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Fecha final del periodo</p>
                                                    <p class="informacionGeneralText">{{dateFormat(periodo.fecha_final_corte, 'modal')}}</p>
                                                </v-col>
                                                <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Fecha inicial de corte:</p>
                                                    <p class="informacionGeneralText">{{dateFormat(periodo.fecha_inicial, 'modal')}}</p>
                                                </v-col>
                                                <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                    <p class="mb-0 informacionGeneralSubtitle">Fecha final de corte:</p>
                                                    <p class="informacionGeneralText">{{dateFormat(periodo.fecha_final, 'modal')}}</p>
                                                </v-col> 
                                            </template>
                                            
                                            <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                <p class="mb-0 informacionGeneralSubtitle">Total de percepciones:</p>
                                                <p class="informacionGeneralText">{{formatNum(totalPercepciones)}}</p>
                                            </v-col>
                                            <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                <p class="mb-0 informacionGeneralSubtitle">Total de deducciones:</p>
                                                <p class="informacionGeneralText">{{formatNum(totalDeducciones)}}</p>
                                            </v-col>
                                            <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                <p class="mb-0 informacionGeneralSubtitle">Total de otros pagos:</p>
                                                <p class="informacionGeneralText">{{formatNum(totalOtrosPagos)}}</p>
                                            </v-col>
                                            <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                <p class="mb-0 informacionGeneralSubtitle">Importe neto de nómina:</p>
                                                <p class="informacionGeneralText">{{formatNum(neto)}}</p>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row class="pa-0 ma-0">
                                        <simple-table
                                            class="tablaWidth"
                                            :rows="itemsEmpleados"
                                            :columns="headersTableEmpleados"
                                            :perPage="[10, 25, 50, 100]"
                                            shortPagination
                                            :modalTable="true"
                                        >
                                            <tbody slot="body" slot-scope="{ data }">
                                                <tr :key="item.id" v-for="(item, index) in data">
                                                    <td class="pa-0">
                                                        <expandable-item :image="urlAvatar + item.empleado.foto" :title="item.empleado.nombre_completo" :valid="item.nomina_valida">
                                                            <template slot="content">
                                                                <table class="tbl-child" width="100%">
                                                                    <tbody>
                                                                        <template>
                                                                            <tr>
                                                                                <td class="pt-4"><p class="subtitle">Días del periodo: </p></td>
                                                                                <td class="pt-4"><p class="text">{{item.empleado.dias_periodo}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Días de pago: </p></td>
                                                                                <td><p class="text">{{item.empleado.dias_de_pago}}</p></td>
                                                                            </tr>
                                                                            <tr v-if="item.empleado.dias_vacaciones != 0">
                                                                                <td><p class="subtitle">Días de vacaciones: </p></td>
                                                                                <td><p class="text">{{item.empleado.dias_vacaciones}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Sueldo diario: </p></td>
                                                                                <td><p class="text">{{formatNum(item.empleado.salario_diario)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Faltas: </p></td>
                                                                                <td><p class="text">{{item.empleado.faltas}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Incapacidades: </p></td>
                                                                                <td><p class="text">{{item.empleado.incapacidades}}</p></td>
                                                                            </tr>
                                                                            <template v-if="item.itemsPercepciones.length != 0">
                                                                                <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                <tr>
                                                                                    <td class="pb-4" style="color: #1E2245; font-weight: bold;">Percepciones:</td>
                                                                                </tr>
                                                                                <tr v-for="itemIn in item.itemsPercepciones" :key="itemIn.id">
                                                                                    <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                    <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                </tr>
                                                                                <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                            </template>

                                                                            <template v-if="item.itemsDeducciones.length != 0">
                                                                                <tr v-if="item.itemsPercepciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                <tr>
                                                                                    <td class="pb-4" style="color: #1E2245; font-weight: bold;">Deducciones:</td>
                                                                                </tr>
                                                                                <tr v-for="itemIn in item.itemsDeducciones" :key="itemIn.id">
                                                                                    <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                    <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                </tr>
                                                                                <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                            </template>

                                                                            <template v-if="item.itemsOtrosPagos.length != 0">
                                                                                <tr v-if="item.itemsDeducciones.length == 0 && item.itemsPercepciones.length == 0"><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                <tr>
                                                                                    <td class="pb-4" style="color: #1E2245; font-weight: bold;">Otros pagos:</td>
                                                                                </tr>
                                                                                <tr v-for="itemIn in item.itemsOtrosPagos" :key="itemIn.id">
                                                                                    <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                    <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                </tr>
                                                                                <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                            </template>
                                                                            <tr>
                                                                                <td><p class="subtitle">Total Percepciones: </p></td>
                                                                                <td><p class="text">{{formatNum(item.total_percepciones)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Total deducciones: </p></td>
                                                                                <td><p class="text">{{formatNum(item.total_deducciones)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                    <td><p class="subtitle">Total otros pagos: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.otros_ingresos)}}</p></td>
                                                                                </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                <td><p class="text">{{formatNum(item.per_excentas)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                <td><p class="text">{{formatNum(item.per_grabado)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="py-4" style="color: #1E2245; font-weight: bold;">Neto a recibir:</td>
                                                                                <td class="py-4 textNeto" style="color: #1E2245; font-weight: bold;">{{formatNum(item.neto_pagar)}}</td>
                                                                            </tr> 
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                            </template>
                                                        </expandable-item>

                                                        <hr style="border: 1.5px solid #F2F2F2;" v-if="index < data.length - 1" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </simple-table>
                                        
                                    </v-row>  
                                </v-container>
                            </v-card-text>
                            </perfect-scrollbar>
                            <v-card-actions>
                                <v-layout row>
                                    <v-flex class="justify-center">
                                        <div class="btnModal">
                                            <button class="btnCerrar modal-pull-right" @click="cerrarModalTabla()">Cerrar</button>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card-actions>
                        </v-card>
                    
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogDownload" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalDownload"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="justify-center">
                            <div class="headerModal">
                                <img :src="imagenDownload" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModalDownload }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text>
                            <div class="loading">
                                <lottie :options="defaultOptions" :height="150" :width="150"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import Datatable from '@/components/g-datatable/Datatable.vue';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import ExpandableItem from '@/components/ExpandableItem.vue';
import queries from '@/queries/Periodos';
import queriesAnios from '@/queries/AnioFiscal';
import Notify from '@/plugins/notify';
import apiPeriodos from '@/api/nominas/periodos';
import apiPeriodicidad from '@/api/nominas/periodicidadPago';
import apiTipoNominaSAT from '@/api/nominas/tipoNominaSAT';
import apiTipoNomina from '@/api/nominas/tipoNomina';
import apiClientes from "@/api/clientes";
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import animationData from '@/animations/cliker.json';
import moment from 'moment';
import GetUrlImg from "@/plugins/Urlimagen";
import clases from '../api/nominas/clases';
moment.locale('es');

export default {
    components: {
        'data-table': Datatable,
        VuetifyDatePicker,
        "simple-table": DatatableSimple,
        ExpandableItem,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Período',
                    name: 'numero',
                    filterable: true,
                    id: 'numero'
                },
                {
                    label: 'Mes Acumulación',
                    name: 'mes_de_acomulacion',
                    filterable: true
                },
                {
                    label: 'Fecha Inicial',
                    name: 'fecha_inicial',
                    filterable: true
                },
                {
                    label: 'Fecha Final',
                    name: 'fecha_final',
                    filterable: true
                },
                {
                    label: 'Fecha Pago',
                    name: 'fecha_de_pago',
                    filterable: true
                },
                {
                    label: 'Estatus',
                    name: 'getEstatus',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align:'center',
                    filterable: false
                }
            ],
            filters         : {AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            periodo                  : {
                id                      : null,
                tipo_nomina_id          : null,
                periodicidad_id         : null,
                tipo_nomina_SAT_id      : null,
                numero                  : null,
                dias_de_pago            : null,
                fecha_inicial           : '',
                fecha_final             : '',
                fecha_inicial_corte     : '',
                fecha_final_corte       : '',
                especial                : false,
                inicio_de_anio          : false,
                fin_de_anio             : false,
                inicio_de_bimestre      : false,
                fin_de_bimestre         : false,
                inicio_de_mes           : false,
                fin_de_mes              : false,
                mes_de_acomulacion      : '',
                descripcion             : '',
                fecha_de_pago           : '',
                numero_de_factura       : '',
                tipo_periodo_especial   : '',
                descontar_faltas        : false,
                descontar_incapacidades : false,
                aplicar_tabla_diaria    : false,
                agregar_sueldos       : true,
                tipo_nomina:{
                    aplicar_fechas_corte: false
                }
            },
            rol : null,
            numero          : null,
            mes_de_acomulacion : null,
            fecha_de_pago      : null,
            fecha_inicial      : null,
            fecha_final        : null,
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            imagen          : "",
            dialog          : false,
            dialogPrecalculo : false,
            isSaving        : false,
            periodicidades  : [],
            tipoNominaSAT   : [],
            tipoNomina      : null,
            calendar_inicio : false,
            calendar_fin    : false,
            calendar_pago   : false,
            calendar_inicio_filter  : false,
            calendar_fin_filter     : false,
            calendar_pago_filter    : false,
            meses:[ 
                {nombre:'Enero',     id:0},
                {nombre:'Febrero',   id:1}, 
                {nombre:'Marzo',     id:2}, 
                {nombre:'Abril',     id:3}, 
                {nombre:'Mayo',      id:4}, 
                {nombre:'Junio',     id:5},
                {nombre:'Julio',     id:6},
                {nombre:'Agosto',    id:7}, 
                {nombre:'Septiembre',id:8}, 
                {nombre:'Octubre',   id:9}, 
                {nombre:'Noviembre', id:10}, 
                {nombre:'Diciembre', id:11}
              ], 
            itemsLinks: [
                {
                text: 'Tipo Nomina',
                disabled: false,
                to: '/tipoNomina',
                },
                {
                text: 'Periodos de pago',
                disabled: true,
                to: '/periodos',
                },
            ],
            perGQL:null,
            idPeriodo: null,
            loading_periodos : false,
            showGuardar     : true,
            disabledSwitch  : false,
            periodicidad_id : null,
            disabledCheck   : false,
            filtersAnios    : {AND:[]},
            anioActual      : null,
            anioFiltro      : null,
            aniosFiltro     : [],

            dialogCalculo   : false,
            imagenCalculo   : "",
            tituloModalCalculo : null,
            isLoadingCalculo : false,
            itemsClientes   : [],
            cliente_value   : null,
            items_empleados : [],
            itemsEmpleados  : [],
            headersTableEmpleados   : [
                { label: "Empleados",  align:'center',  name: "nombre_completo",    filterable: false },
            ],
            modelo_empleados :[],
            invalidBtnConsultar : false,
            invalidBtnGuardar : false,
            btnConsultar : false,
            btnGuardar : false,
            btnCerrar  : false,
            dialogCalculoTabla : false,
            imagenCalculoTabla      : '/static/icon/consultar-calculo.png',
            tituloModalCalculoTabla : "Resultados de la consulta",
            isLoadingCalculoTabla : false,
            dialogDownload : false,
            imagenDownload : "",
            tituloModalDownload : "",
            defaultOptions          : { animationData: animationData },
            incidencias_reloj_checador  : false,
            incidencias_capturadas      : false,
            modificaciones_adicionales  : false,
            cambio_empleado         : false,
            urlAvatar               : null,
            visualizarPeriodo       : false,
            deshabilitarGenerar     : true,
            totalPercepciones       : 0,
            totalDeducciones        : 0,
            totalOtrosPagos         : 0,
            totalEmpleados          : 0,
            nombreEmpresa           : null,
            periodicidad            : null,
            neto                    : null,
            showGenerar             : false,
            switchPeriodos          : false,
            datosTabla              : [],
            isLoadingTablaPeriodos  : false,
            isSavingTabla           : false,
           
        }
    },
    watch: {
        switchPeriodos :function(val, oldVal) {
            if(val){
                this.getTablaPeriodos();
            }
            else{
                this.$apollo.queries.periodos.refetch();
            }
        },
        periodo:{
            deep: true,
            handler(periodo){
                if(periodo.especial == true){
                    periodo.inicio_de_anio          = false;
                    periodo.fin_de_anio             = false;
                    periodo.inicio_de_bimestre      = false;
                    periodo.fin_de_bimestre         = false;
                    periodo.inicio_de_mes           = false;
                    periodo.fin_de_mes              = false;
                    this.disabledSwitch             = true;
                }
                else{

                    if (this.visualizarPeriodo  == true){
                        this.disabledSwitch             = true;
                    }else{
                        this.disabledSwitch             = false;
                    }
                    
                    periodo.periodicidad_id         = this.periodicidad_id;
                    periodo.tipo_periodo_especial   = '';
                    periodo.descontar_faltas        = false;
                    periodo.descontar_incapacidades = false;
                }
                if(periodo.tipo_periodo_especial!=''){
                    this.disabledCheck= false;
                }
                else{
                    this.disabledCheck= true;
                }


                if( periodo.fecha_inicial != '' && periodo.fecha_final != '' && 
                    periodo.fecha_inicial != null && periodo.fecha_final != null && 
                    periodo.fecha_inicial.length == 10 && periodo.fecha_final.length == 10 &&
                    this.tipoNomina.periodicidad.clave != "04"
                ){
                    if((moment(periodo.fecha_final).diff(moment(periodo.fecha_inicial), 'days') + 1 ) > 0){
                        periodo.dias_de_pago = (moment(periodo.fecha_final).diff(moment(periodo.fecha_inicial), 'days') + 1 );
                    }
                    else{
                        Notify.ErrorToast("El rango de fechas es incorrecto.");
                        periodo.dias_de_pago = null;
                    }
                }

                if( periodo.fecha_inicial != '' && periodo.fecha_final != '' && 
                    periodo.fecha_inicial != null && periodo.fecha_final != null && 
                    periodo.fecha_inicial.length == 10 && periodo.fecha_final.length == 10 &&
                    this.tipoNomina.dias_quincena == "CALENDARIO"
                ){
                    if((moment(periodo.fecha_final).diff(moment(periodo.fecha_inicial), 'days') + 1 ) > 0){
                        periodo.dias_de_pago = (moment(periodo.fecha_final).diff(moment(periodo.fecha_inicial), 'days') + 1 );
                    }
                    else{
                        Notify.ErrorToast("El rango de fechas es incorrecto.");
                        periodo.dias_de_pago = null;
                    }
                }

                if(this.tipoNomina.dias_quincena == "15"){
                    periodo.dias_de_pago = 15
                }
                if(this.tipoNomina.dias_quincena == "15.20"){
                    periodo.dias_de_pago = 15.20
                }
            }
        },
        'periodo.fecha_de_pago': function(val, oldVal) {
            let reactivo = true;

            if(this.accion == 'add'){
                reactivo = true;
            }
            else{
                reactivo = false;
            }

            if( this.accion == 'update' && val != null && val != '' && oldVal != null && oldVal != '' ){
                reactivo = true;
            }

            if(val != null && val != '' && val.length == 10 && reactivo){
                let array = (val.split("-"));
                    switch(array[1]) {
                        case "01":
                            this.periodo.mes_de_acomulacion = "Enero";
                            break;
                        case "02":
                            this.periodo.mes_de_acomulacion = "Febrero";
                            break;
                        case "03":
                            this.periodo.mes_de_acomulacion = "Marzo";
                            break;
                        case "04":
                            this.periodo.mes_de_acomulacion = "Abril";
                            break;
                        case "05":
                            this.periodo.mes_de_acomulacion = "Mayo";
                            break;
                        case "06":
                            this.periodo.mes_de_acomulacion = "Junio";
                            break;
                        case "07":
                            this.periodo.mes_de_acomulacion = "Julio";
                            break;
                        case "08":
                            this.periodo.mes_de_acomulacion = "Agosto";
                            break;
                        case "09":
                            this.periodo.mes_de_acomulacion = "Septiembre";
                            break;
                        case "10":
                            this.periodo.mes_de_acomulacion = "Octubre";
                            break;
                        case "11":
                            this.periodo.mes_de_acomulacion = "Noviembre";
                            break;
                        case "12":
                            this.periodo.mes_de_acomulacion = "Diciembre";
                            break;
                    }
            }
        },
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        //funciones del precualculo
        abrirModalPrecalculo(data = null){
            
            this.isLoadingModal  = true;
            if(this.rol == 'root'){
                this.getClientes();
            }
            else{
                this.cliente_value =  this.datosLogin.cliente_id;
            }
            
                this.showGuardar = true;

                this.tituloModal    = "Precálcular nómina";
                this.imagen         = "/static/modal/preCalculoNominaUpdate.svg";
                this.getEmpleados(data);
                this.periodo.id                      = data.id;                    
                this.periodo.tipo_nomina_id          = data.tipo_nomina_id;
                this.periodo.periodicidad_id         = data.periodicidad_id;
                this.periodicidad_id                 = data.periodicidad_id;
                this.periodo.tipo_nomina_SAT_id      = data.tipo_nomina_SAT_id;
                this.periodo.numero                  = data.numero;
                
                this.periodo.tipo_nomina.aplicar_fechas_corte = data.tipoNomina.aplicar_fechas_corte;
                this.periodo.dias_de_pago            = data.dias_de_pago;
                this.periodo.fecha_inicial           = data.fecha_inicial;
                this.periodo.fecha_final             = data.fecha_final;
                this.periodo.fecha_inicial_corte     = data.fecha_inicial_corte;
                this.periodo.fecha_final_corte       = data.fecha_final_corte;
                this.periodo.especial                = data.especial;
                this.periodo.inicio_de_anio          = data.inicio_de_anio;
                this.periodo.fin_de_anio             = data.fin_de_anio;
                this.periodo.inicio_de_bimestre      = data.inicio_de_bimestre;
                this.periodo.fin_de_bimestre         = data.fin_de_bimestre;
                this.periodo.inicio_de_mes           = data.inicio_de_mes;
                this.periodo.fin_de_mes              = data.fin_de_mes;
                this.periodo.mes_de_acomulacion      = data.mes_de_acomulacion;
                this.periodo.descripcion             = data.descripcion;
                this.periodo.fecha_de_pago           = data.fecha_de_pago;
                this.periodo.numero_de_factura       = data.numero_de_factura;
                this.periodo.actual                  = data.actual;
                this.periodo.anterior_actual         = data.actual;
                this.periodo.tipo_periodo_especial   = data.tipo_periodo_especial;
                this.periodo.descontar_faltas        = data.descontar_faltas;
                this.periodo.descontar_incapacidades = data.descontar_incapacidades;
                this.periodo.aplicar_tabla_diaria    = data.aplicar_tabla_diaria;
                this.periodo.agregar_sueldos       = data.agregar_sueldos;
                setTimeout(()=>{
                    this.$refs.FechaPago.setDate(data.fecha_de_pago);

                },200);
            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModalPrecalculo").click(); }, 100);
        },
        consultar(){
            let self = this;
            this.isLoadingCalculoTabla = true;
            setTimeout(function(){ document.getElementById("btnModalCalculoTabla").click(); }, 100);
            let empleados = [];
            this.modelo_empleados.forEach((element) => {
                empleados.push({empleado_id: element});
            });

            let parametros = { 
                periodo_id:this.periodo.id,
                tipo_nomina_id:this.periodo.tipo_nomina_id, 
                incidencias_checador:this.incidencias_reloj_checador, 
                incidencias_capturadas:this.incidencias_capturadas,
                modificaciones_adicionales:false,
                tipo_nomina : 'GENERAL',
                accion: 'RECONSULTAR', 
                tipo_reporte: "empleados", 
                empleados : empleados }

            apiCalculoNomina.calcularPeriodo(parametros).then((response) => {
                self.totalPercepciones       = response.totales.percepciones;
                self.totalDeducciones        = response.totales.deducciones;
                self.totalOtrosPagos         = response.totales.otros_pagos;
                self.neto                    = response.totales.neto;
                self.totalEmpleados          = 0;
                

                let formatEmpleados = response.data;

                if(formatEmpleados.length != 0){
                    self.totalEmpleados    = formatEmpleados.length;
                    formatEmpleados.forEach(empleado => {
                        empleado.itemsPercepciones  = [];
                        empleado.itemsDeducciones   = [];
                        empleado.itemsOtrosPagos    = [];
                        empleado.itemsInformativos  = [];
                        
                        if(parseFloat(empleado.empleado.total_sueldo) != 0){
                            empleado.itemsPercepciones.push({
                                codigo : 'P1000',
                                nombre : 'Sueldo',
                                total  : empleado.empleado.total_sueldo
                            });
                        }
                        empleado.incidencias.forEach(incidencia =>{
                            switch (incidencia.codigo_incidencia.charAt(0)) {
                                case 'P':
                                    let complemento = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsPercepciones.push(complemento);
                                    }
                                    
                                    break;
                                case 'D':
                                    let complemento2 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsDeducciones.push(complemento2);
                                    }
                                    break;
                                case 'O':
                                    let complemento3 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsOtrosPagos.push(complemento3);
                                    }
                                    break;  
                                case 'I':
                                    let complemento4 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsInformativos.push(complemento4);
                                    }
                                    break;  
                                default:
                                    break;
                            }  
                        })

                        if(empleado.ajuste_mensual){
                            empleado.ajuste_mensual.forEach(incidencia =>{
                                switch (incidencia.codigo.charAt(0)) {
                                    case 'P':
                                        let complemento = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsPercepciones.push(complemento);
                                        }
                                        break;
                                    case 'D':
                                        let complemento2 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsDeducciones.push(complemento2);
                                        }
                                        break;
                                    case 'O':
                                        let complemento3 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsOtrosPagos.push(complemento3);
                                        }
                                        break;  
                                    case 'I':
                                        let complemento4 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsInformativos.push(complemento4);
                                        }
                                        break;  
                                    default:
                                        break;
                                }  
                            })
                        }
                        

                        if(parseFloat(empleado.subsidio) != 0){
                            empleado.itemsOtrosPagos.push({
                                codigo : 'O999',
                                nombre : 'Subsidio a entregar',
                                total  : empleado.subsidio
                            });
                        }
                        if(parseFloat(empleado.isr) != 0){
                            empleado.itemsDeducciones.push({
                                codigo : 'D998',
                                nombre : 'ISR',
                                total  : empleado.isr
                            });
                        }
                        if(parseFloat(empleado.imss) != 0){
                            empleado.itemsDeducciones.push({
                                codigo : 'D999',
                                nombre : 'IMSS',
                                total  : empleado.imss
                            });
                        }
                    })
                }

                this.itemsEmpleados = formatEmpleados;
                this.isLoadingCalculoTabla = false;
                if(!response.valid){
                    Notify.Error("ADVERTENCIA","Uno o más empleados presentan problemas en el cálculo de su nómina", null, 'Enterado')
                }
            })
            .catch(err => {
                console.log(err)
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
                this.isSaving = false;
                this.cerrarModalTabla();
            });
        },
        async descargarReporte(item, tipo){
            let sefl = this;
            this.btnGuardar = true;
            this.invalidBtnConsultar = true;
            this.btnCerrar = true;

            let url = "calculo-nomina/calcular-periodo";
            const FileDownload = require("js-file-download");
            var today = new Date();
            let nombre;
            nombre = this.tipoNomina.clave + "-" + this.tipoNomina.descripcion + " - Periodo " + this.periodo.numero + ".xlsx";
            let empleados = [];
            this.modelo_empleados.forEach((element) => {
                empleados.push({empleado_id: element});
            });
            

            let parametros = { 
                periodo_id:this.periodo.id,
                tipo_nomina_id:this.periodo.tipo_nomina_id, 
                incidencias_checador:this.incidencias_reloj_checador, 
                incidencias_capturadas:this.incidencias_capturadas,
                modificaciones_adicionales:false,
                tipo_nomina : 'GENERAL',
                accion: 'RECALCULAR', 
                tipo_reporte: "empleados", 
                empleados : empleados }

            const object1 = parametros;
            
            try {
                await Vue.axios({
                method: "POST",
                responseType: "blob",
                url: url,
                data: object1,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.dialogPrecalculo = false;
                    this.btnGuardar = false;
                    this.invalidBtnConsultar = false;
                    this.btnCerrar = false;
                    this.loadingLottie =  false;
                    Notify.Success("Operación exitosa", "Se ha realizado el guardado del precalculo satisfactoriamente.");
                })
                .catch(async error =>{
                    this.dialogPrecalculo = false;
                    this.btnGuardar = false;
                    this.invalidBtnConsultar = false;
                    this.btnCerrar = false;
                    this.loadingLottie =  false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }
                });
            } catch (err) {
                if(err.response.status == 405){
                    Notify.ErrorToast("No se puede realizar la operación, la nomina ya se encuentra acumulada.");
                }else{
                    Notify.ErrorToast("Se presento un error favor de contactar a un administrador.");
                }
                this.dialogPrecalculo = false;
                this.btnGuardar = false;
                this.invalidBtnConsultar = false;
                this.btnCerrar = false;
                this.loadingLottie =  false;
            }
        },
        cerrarModalTabla(){
            this.dialogCalculoTabla = false;        
        },
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },
        cambio_emp(){
            this.cambio_empleado = true;
        },
        async getClientes(){
            let paramClientes = {
                activo      : true, 
                paginate    : false,
                cliente_id  : this.datosLogin.cliente_id
            }
            await apiClientes.getClientes(paramClientes).then((response) => {
                this.itemsClientes = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los clientes");
            })
        },
      /*async abrirModalPrecalculo(datos, tipoCalculo){
            this.isLoadingCalculo   = true;
            this.accionCalculo = tipoCalculo;
            this.imagenCalculo      = "/static/icon/catalogos.png";
            this.tipoCalculo = tipoCalculo;
            await this.getEmpleados(datos);
            this.itemCalculo =  datos;
            this.isLoadingCalculo   = false;
            setTimeout(function(){ document.getElementById("btnModalCalculo").click(); }, 100);
        },*/
        async getEmpleados(datos) {
            let param = {tipo_nomina_id:datos.tipo_nomina_id, periodo_id:datos.id, tipo: 'GENERAL'}
            await apiTipoNomina.getEmpleados(param).then((response) => {
                this.items_empleados = response.data;
                if(response.data.length == 0){
                    this.invalidBtnGuardar = true;
                    this.invalidBtnConsultar = true;
                }
                else{
                    this.invalidBtnGuardar = false;
                    this.invalidBtnConsultar = false;
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los empleados.");
            })
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoadingModal  = true;
            this.imagen = "/static/modal/periodo.svg";
            this.getTipoNominaSAT();
            this.getPeriodicidades();
            this.periodo.tipo_nomina_id = this.tipoNomina.id;
            if(accion == "add"){
                this.visualizarPeriodo = false;
                this.isSaving = false;
                this.tituloModal = "Registrar Periodo";
                this.periodo.periodicidad_id         = this.tipoNomina.periodicidad_id;
                this.periodicidad_id                 = this.tipoNomina.periodicidad_id;
            } else {
                if(data.cerrado && data.getEstatus != 'Desacumulado'){
                    this.showGuardar = false;
                }
                else{
                    this.showGuardar = true;
                }        
                this.tituloModal                     = "Actualizar periodo";
                this.periodo.id                      = data.id;                    
                this.periodo.tipo_nomina_id          = data.tipo_nomina_id;
                this.periodo.periodicidad_id         = data.periodicidad_id;
                this.periodicidad_id                 = data.periodicidad_id;
                this.periodo.tipo_nomina_SAT_id      = data.tipo_nomina_SAT_id;
                this.periodo.numero                  = data.numero;
                this.periodo.dias_de_pago            = data.dias_de_pago;
                this.periodo.especial                = data.especial;
                this.periodo.inicio_de_anio          = data.inicio_de_anio;
                this.periodo.fin_de_anio             = data.fin_de_anio;
                this.periodo.inicio_de_bimestre      = data.inicio_de_bimestre;
                this.periodo.fin_de_bimestre         = data.fin_de_bimestre;
                this.periodo.inicio_de_mes           = data.inicio_de_mes;
                this.periodo.fin_de_mes              = data.fin_de_mes;
                this.periodo.mes_de_acomulacion      = data.mes_de_acomulacion;
                this.periodo.descripcion             = data.descripcion;
                this.periodo.fecha_de_pago           = data.fecha_de_pago;
                this.periodo.numero_de_factura       = data.numero_de_factura;
                this.periodo.actual                  = data.actual;
                this.periodo.anterior_actual         = data.actual;
                this.periodo.tipo_periodo_especial   = data.tipo_periodo_especial;
                this.periodo.descontar_faltas        = data.descontar_faltas;
                this.periodo.descontar_incapacidades = data.descontar_incapacidades;
                this.periodo.aplicar_tabla_diaria    = data.aplicar_tabla_diaria;
                this.periodo.agregar_sueldos       = data.agregar_sueldos;


                if(this.tipoNomina.aplicar_fechas_corte) {
                    this.periodo.fecha_inicial           = data.fecha_inicial_corte;
                    this.periodo.fecha_final             = data.fecha_final_corte;
                    this.periodo.fecha_inicial_corte     = data.fecha_inicial;
                    this.periodo.fecha_final_corte       = data.fecha_final;
                }
                else {
                    this.periodo.fecha_inicial           = data.fecha_inicial;
                    this.periodo.fecha_final             = data.fecha_final;
                }





                if(data.cerrado === true && data.getEstatus != 'Desacumulado'){
                    this.visualizarPeriodo  = true;
                    this.tituloModal        = "Visualizar periodo";
                }
                else{
                    this.visualizarPeriodo = false;
                }
                
                // setTimeout(()=>{
                //     this.$refs.FechaInicial.setDate(data.fecha_inicial);
                //     this.$refs.FechaFinal.setDate(data.fecha_final);
                //     this.$refs.FechaPago.setDate(data.fecha_de_pago);

                // },200);

            }

            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.dialogPrecalculo = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.periodo                  = {
                tipo_nomina_id          : null,
                periodicidad_id         : null,
                tipo_nomina_SAT_id      : null,
                numero                  : null,
                dias_de_pago            : null,
                fecha_inicial           : '',
                fecha_final             : '',
                fecha_inicial_corte     : '',
                fecha_final_corte       : '',
                especial                : false,
                inicio_de_anio          : false,
                fin_de_anio             : false,
                inicio_de_bimestre      : false,
                fin_de_bimestre         : false,
                inicio_de_mes           : false,
                fin_de_mes              : false,
                mes_de_acomulacion      : '',
                descripcion             : '',
                fecha_de_pago           : '',
                numero_de_factura       : '',
                tipo_periodo_especial   : '',
                descontar_faltas        : false,
                descontar_incapacidades : false,
                aplicar_tabla_diaria    : false,
                agregar_sueldos       : true,
                tipo_nomina:{
                    aplicar_fechas_corte: false
                }
            };
            this.showGuardar    = true;

            if(this.$refs.FechaInicial != undefined || this.$refs.FechaInicial != null){
                this.$refs.FechaInicial.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
            if(this.$refs.FechaPago != undefined || this.$refs.FechaPago != null){
                this.$refs.FechaPago.resetDate();
            }
            if(this.$refs.FechaInicialFilter != undefined || this.$refs.FechaInicialFilter != null){
                this.$refs.FechaInicialFilter.resetDate();
            }
            if(this.$refs.FechaFinalFilter != undefined || this.$refs.FechaFinalFilter != null){
                this.$refs.FechaFinalFilter.resetDate();
            }
            if(this.$refs.FechaPagoFilter != undefined || this.$refs.FechaPagoFilter != null){
                this.$refs.FechaPagoFilter.resetDate();
            }

            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                } 
                if(this.$refs.formTabla != undefined || this.$refs.formTabla != null){
                    this.$refs.formTabla.reset();
                } 
                if(this.$refs.formPrecalculo != undefined || this.$refs.formPrecalculo != null){
                    this.$refs.formPrecalculo.reset();
                } 

                this.numero = null;
                this.mes_de_acomulacion = null;
                this.fecha_de_pago = null;
                this.fecha_inicial = null;
                this.fecha_final = null;
            });
        },
        loadModalData() {
            this.anioFiltro = this.anioActual.id;
        },
        setFilters() {
            this.filters = { AND:[]};
            
            this.filters.AND.push({column:'TIPO_NOMINA_ID', value:this.tipoNomina.id});
            this.filters.AND.push({column:'TIPO_PERIODO_ESPECIAL', value:null});

            let numero = this.numero;
            let mes_de_acomulacion = this.mes_de_acomulacion;
            let fecha_de_pago = this.fecha_de_pago;
            let fecha_inicio = this.fecha_inicial;
            let fecha_final = this.fecha_final;
            let anio = this.anioFiltro;
            
    
            this.isLoading = true;
            if(anio != null && anio != undefined && anio != ""){
                this.filters.AND.push({column:'ANIO_FISCAL_ID', value:anio})
            }

            //Para nominas que aplican fechas de corte.
            if(this.tipoNomina.aplicar_fechas_corte){
                if(fecha_inicio != null && fecha_inicio != undefined && fecha_inicio != ""){
                    this.filters.AND.push({ column: "FECHA_INICIAL_CORTE", operator: "GTE" , value: fecha_inicio});
                }

                if(fecha_final != null && fecha_final != undefined && fecha_final != ""){
                    this.filters.AND.push({ column: "FECHA_FINAL_CORTE", operator: "LTE" , value: fecha_final});
                }
            }
            else {
                if(fecha_inicio != null && fecha_inicio != undefined && fecha_inicio != ""){
                    this.filters.AND.push({ column: "FECHA_INICIAL", operator: "GTE" , value: fecha_inicio});
                }

                if(fecha_final != null && fecha_final != undefined && fecha_final != ""){
                    this.filters.AND.push({ column: "FECHA_FINAL", operator: "LTE" , value: fecha_final});
                }
            }


            if(numero != null && numero != undefined && numero != ""){
                this.filters.AND.push({column:'NUMERO', value:numero});
            }

            if(mes_de_acomulacion != null && mes_de_acomulacion != undefined && mes_de_acomulacion != ""){
                this.filters.AND.push({column:'MES_DE_ACOMULACION',operator:'LIKE',value:mes_de_acomulacion});
            }

            if(fecha_de_pago != null && fecha_de_pago != undefined && fecha_de_pago != ""){
                this.filters.AND.push({column:'FECHA_DE_PAGO',operator:'BETWEEN', value:[fecha_de_pago,fecha_de_pago]});
            }
            
            this.paginationData.numberPage = 1;
            this.$apollo.queries.periodos.refetch();
            this.resetValues();
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el periodo " + data.descripcion + "?",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiPeriodos.delete(data.id).then(response => {
                        Notify.Success("Periodo eliminado", "El periodo ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.periodos.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        }, 
        async guardar() {
            let inicio = new Date(this.periodo.fecha_inicial);
            let final = new Date(this.periodo.fecha_final);
            
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                let periodicidadActual = this.periodicidades.find(element => element.id == this.periodo.periodicidad_id);

                if(inicio > final){
                    Notify.ErrorToast("El rango de fechas es incorrecto.");
                    this.isSaving = false;
                    return;
                }
                if(this.periodo.inicio_de_anio && this.periodo.fin_de_anio){
                    Notify.ErrorToast("Inicio de año y fin de año no pueden estar seleccionados al mismo tiempo.");
                    this.isSaving = false;
                    return;
                }
                if(this.periodo.inicio_de_bimestre && this.periodo.fin_de_bimestre && periodicidadActual.clave != '05' && periodicidadActual.clave != '06'){
                    Notify.ErrorToast("Inicio de bimestre y fin de bimestre no pueden estar seleccionados al mismo tiempo.");
                    this.isSaving = false;
                    return;
                }
                if(this.periodo.inicio_de_mes && this.periodo.fin_de_mes && periodicidadActual.clave != '05' && periodicidadActual.clave != '06'){
                    Notify.ErrorToast("Inicio de mes y fin de mes no pueden estar seleccionados al mismo tiempo.");
                    this.isSaving = false;
                    return;
                }

                let diferenciaDias =  moment(this.periodo.fecha_final).diff(moment(this.periodo.fecha_inicial), 'days');

                if((diferenciaDias+1) != periodicidadActual.dias){
                    Notify.Alert(
                        "Diferencia de días",
                        "La diferencia de dias entre la fecha inicial y la fecha final no corresponde a la periodicidad seleccionada, ¿Quiere continuar?",
                        "Continuar",
                        () => {
                            this.guardarPeriodo();
                        },
                        () =>{
                            this.isSaving =false;
                            return;
                        }
                    )
                }else{
                    this.guardarPeriodo();
                }
            });
        },
        guardarPeriodo(){
            this.isLoading = true;
            if(this.accion === "add") {
                apiPeriodos.store(this.periodo)
                .then((response) => {
                    Notify.Success("Operación exitosa", "El Periodo se guardó satisfactoriamente.");
                    this.$apollo.queries.periodos.refetch();
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isLoading = false;
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            else if(this.accion === "update") {
                apiPeriodos.update(this.periodo)
                .then((response) => {
                    Notify.Success("Operación exitosa", "El Periodo se actualizó satisfactoriamente.");
                    this.$apollo.queries.periodos.refetch();
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
        },
        getPeriodicidades(){
            let parametros = {
                activo      : true, 
                paginate    : false,
                order       : 'asc'
            }
            apiPeriodicidad.find(parametros).then(response =>{
                this.periodicidades = response.data;
            })
            .catch(err => {
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            });
        },
        getTipoNominaSAT(){
            let parametros = {
                activo      : true, 
                paginate    : false,
                order       : 'asc'
            }
            apiTipoNominaSAT.find(parametros).then(response =>{
                this.tipoNominaSAT = response.data;
            })
            .catch(err => {
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            });
        },
        generarPeriodos(){
            this.loading_periodos   = true;
            let param               = { tipo_nomina_id:this.tipoNomina.id }
            apiPeriodos.generar(param)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "Se generaron correctamente los periodos");
                        this.$apollo.queries.periodos.refetch();
                        this.cerrarModal();
                        this.loading_periodos   = false;
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.loading_periodos   = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }                        
                    });
        },
        periodo_actual(actual){
            if(actual){
                return 'periodo_actual';
            }
        },
        dateFormat(fecha, tipo) {
            if(fecha == null){
                return;
            }
            let fecha2 = fecha.split("-");
            if(tipo == 'tabla'){
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
            else{
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }
            
        },
        getColor(estatus) {
            switch (estatus) {
                case "Abierto":
                    return "#EFF8F3";
                    break;
                case "Actual":
                    return "rgba(45, 185, 209, 0.1)";
                    break;
                case "Acumulado":
                    return "#F9F9F9";
                    break;
                case "Cerrado":
                    return "#F9F9F9";
                    break;
                case "Desacumulado":
                    return "#FAE6E599";
                    break;
                case undefined:
                    return "";
                    break;
                default:
                    return "#F9F9F9";
                    break;
            }
        },
        getColorTexto(estatus) {
            switch (estatus) {
                case "Abierto":
                    return "#219653";
                    break;
                case "Actual":
                    return "#2DB9D1";
                    break;
                case "Acumulado":
                    return "#BDBDBD";
                    break;
                case "Cerrado":
                    return "#BDBDBD";
                    break;
                case "Desacumulado":
                    return "#D93025";
                    break;
                case undefined:
                    return "";
                    break;
                default:
                    return "#BDBDBD";
                    break;
            }
        },
        presicionNumero(event, valor){
            if (/\.\d{3}/.test(valor)) 
                return event.preventDefault();
        },
        redondearNumero(numero){
            return Math.round(numero*100)/100
        },
        async getTablaPeriodos(item){
            this.isLoadingTablaPeriodos = true;
            this.datosTabla = [];
            let parametros = {tipo_nomina_id: this.tipoNomina.id}
            await apiTipoNomina.getPeriodos(parametros).then((response) => {
                this.datosTabla = response.periodos;
                this.isLoadingTablaPeriodos = false;
            })
            .catch(err => {
                this.isLoadingTablaPeriodos = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },
        guardarPeriodos(){
            this.$refs.formTabla.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                let periodos = {periodos:this.datosTabla}
                apiTipoNomina.storePeridos(periodos).then((response) => {
                    Notify.Success("Operación exitosa", "La configuración se guardó satisfactoriamente.");
                    this.isSaving = false;
                    this.getTablaPeriodos();
                })
                .catch(err => {
                    this.isSaving = false;
                    this.getTablaPeriodos();
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            });
        },
    },
    beforeCreate(){
        if(!this.$session.get('periodos')){
            this.$router.push('/dashboard');
        }
    },
    created : function (){
        this.urlAvatar = GetUrlImg.getURL();
        this.tipoNomina = this.$session.get('periodos');
        console.log(this.tipoNomina)
        if(
            this.tipoNomina.periodicidad.clave === '07' ||
            this.tipoNomina.periodicidad.clave === '08' ||
            this.tipoNomina.periodicidad.clave === '09' ||
            this.tipoNomina.periodicidad.clave === '99' 
        ){
            this.showGenerar = false;
        }
        else{
            this.showGenerar = true;
        }

        this.datosLogin = this.$session.get('usuario');
        this.rol = this.datosLogin.rol.name;
        if (this.rol != "root") {
            this.cliente_value = this.datosLogin.cliente_id;
        }
        this.nombreEmpresa  = this.tipoNomina.empresa.nombre;
        this.periodicidad   = this.tipoNomina.periodicidad.descripcion;
        this.filtersAnios.AND.push({column:'CLIENTE_ID', value:this.tipoNomina.cliente_id});
        this.filtersAnios.AND.push({column:'EMPRESA_ID', value:this.tipoNomina.empresa_id});

        this.filters.AND.push({column:'TIPO_NOMINA_ID', value:this.tipoNomina.id});
        this.filters.AND.push({column:'TIPO_PERIODO_ESPECIAL', value:null});
    },
    mounted(){
        this.$apollo.queries.anios_fiscales.skip = false;

        document.querySelector("#numero").click();
    },
    updated() {    
        let tableScrollSimple = document.querySelector('#tableScrollSimple');

        if(tableScrollSimple){
            document.querySelector('#tableScrollSimple tbody').style.minWidth = "400px";
        }
    },
    apollo: {
        periodos: {
            query       : queries.periodosTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereConditions: this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'numero',
                    Order       : this.$refs.tabla.Order
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                if(error){
                    Notify.ErrorToast("Se presento un error al realizar la petición");
                    return;
                }
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.periodos;   
                if(this.periodos.data.length != 0){
                    this.deshabilitarGenerar = false;
                }
                else{
                    this.deshabilitarGenerar = true;
                }
            },
        },
        anios_fiscales: {
            query   : queriesAnios.aniosFiscalesTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    whereConditions: this.filtersAnios,
                    numberItems : 1000,
                    numberPage  : 1,
                    fieldOrder  : 'anio_id',
                    Order       : 'ASC'
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                if(error){
                    Notify.ErrorToast("Se presento un error al realizar la petición");
                    return;
                }

                if(this.anios_fiscales.data.length != 0){
                    this.aniosFiltro = this.anios_fiscales.data;
                    this.anioActual = this.anios_fiscales.data.find(item => item.actual=== true);

                    if(this.anioActual == undefined){
                        Notify.Warning("ADVERTENCIA",
                            "Favor de definir un año fiscal como actual",
                            "Ir a año fiscal",
                            () => {
                                this.$router.push('/anual_fiscal');
                            }
                        )
                    }

                    this.filters.AND.push({column:'ANIO_FISCAL_ID', value:this.anioActual?.id});

                    this.$apollo.queries.periodos.skip = false;
                }
                else{
                    Notify.Warning("ADVERTENCIA",
                        "Favor de crear un año fiscal",
                        "Ir a año fiscal",
                        () => {
                            this.$router.push('/anual_fiscal');
                        }
                    )
                    let periodos = {data:[]}
                    this.isLoading = false;
                    this.tableData  = periodos;  
                }
                
            },
        },
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .periodo_actual{
    /*  color: #002438; */
        color: #24d40d;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.34px;
        line-height: 17px;
        text-align: left;
    }
    .material-icons-outlined {
        font-family: 'Material Icons Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }  
    .tablaWidth{
        width: 100%;
        width: -moz-available;          
        width: -webkit-fill-available;  
        width: fill-available;
    }
    .vChip {
        background-color: transparent;
        width: 160px;
        justify-content: center;
        border-radius: 10px;
    }
    .vChip .v-chip .v-chip__content {
        height: 20px;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    #tableScrollSimple tbody{
        max-height:none !important;
        min-width: 100% !important;
        background-color: #fff !important;
    }
    .tbl-child tbody td:first-child {
        padding-left: 35px !important;
        width: 320px;
    }
    
    .informacionGeneral{
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    th{
        color:#828282;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 8px;
        text-align: left;
    }

    .tbl-incidencias tbody{
        background:#FFFFFF;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        margin-bottom: 10em;
    }

    .tbl-incidencias tbody td{
        color: #1E2144;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 2px;
        text-align: left;
    }

    .tbl-incidencias {
        width: 100%;
        /* border-collapse: collapse;
        margin-top: 15px;
        overflow-x: scroll;
        overflow-y: scroll; */
    }

    .tbl-incidencias .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .firstth {
        border-top-left-radius: 20px !important;
    }
    .lastth {
        border-top-right-radius: 20px !important;
    }
    .emptyTableComplementos{
        color: #c5c5c5 !important;
        background:#FFFFFF;
        font-family: "Montserrat" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        padding-bottom: 50px !important;
        padding-top: 50px !important;
        text-align: center !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
    }
    .thCheck{
        min-width: 56px;
    }
    .thTitulos{
        background:#F2F2F2;
        border-width:1px; 
        border-color:#E0E0E0 ;
        border-bottom-style: solid;
    }
    .tdBlanco{
        color: #c5c5c5 !important;
        background:#FFFFFF;
        font-family: "Montserrat" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        padding-bottom: 20px !important;
        padding-top: 20px !important;
        text-align: center !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
    }
    .trBody{
        border-width:1px; 
        border-color:#E0E0E0 ;
        border-bottom-style: solid;
        margin-top: 10px!important;
    }
    .spanSwitch{
        height: 42px;
    }

    .fade-enter-active {
        transition: opacity 1s ease;
    }
    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }
    .menuClassAcciones{
        border-radius: 10px!important;
    }
    .div-tabla-periodos{
        height: 73vh!important;
        overflow: scroll;
    }

    .tabla-periodos{
        border-collapse: collapse;
	    border-spacing: 0;
    }
    
    .tabla-periodos>thead>tr>th {
        background: #F6F6F6;
        top: 0px;
        position: sticky;
        z-index: 999;
    }
</style>
